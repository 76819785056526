import React from "react"
import { Link, graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  SectionPage,
  SectionHeading,
  MainPanel,
  LeftPanel,
  RightPanel,
  BreadCrumb,
  MarkdownContent,
  Container,
} from "../components/Section"
import { SidebarSticky } from "../components/Sidebar"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import TabProductCardList from "../components/TabProductCardList"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import ProductCustomBuildingForm from "../components/ProductCustomBuildingForm"
import generateHTML from "../utils/generateHTML"
import GetTouchForm from "../components/GetTouchForm"
import ProductCardList from "../components/ProductCardList"
// import LazyLaod from "react-lazyload"
// import CardSection from "../sections/Home/CardSection"
// import {
//   DetailButton,
//   DurableItem,
//   DurableSectionContent,
//   DurableSteelBuildingData,
//   DurableSteelBuildingImage,
// } from "../sections/SteelBuildings/styles"
// import Img from "gatsby-image"
// import { RightArrowChevIcon } from "../components/Icons/ArrowIcons"

const ProductCategoryTemplate = ({ data, location }) => {
  const pageData = data.contentfulProductCategoryLanding
  const totalProducts = data.allContentfulProduct.edges
  const currentData = totalProducts.filter(
    edge => edge.node.productCategory && edge.node.productCategory.name === pageData.category.name
  )

  const durableItems = [
    {
      name: "Utility Buildings",
      url: "/utility-buildings/",
      image: data.utilityImage,
    },
    {
      name: "Storage Buildings",
      url: "/storage-buildings/",
      image: data.storageImage,
    },
    {
      name: "Commercial Buildings",
      url: "/commercial-metal-buildings/",
      image: data.commercialImage,
    },
    { name: "Metal Sheds", url: "/metal-sheds/", image: data.metalShedsImage },
    {
      name: "Metal Warehouses",
      url: "/metal-warehouses/",
      image: data.metalWarehousesImage,
    },
    {
      name: "Metal Building Homes",
      url: "/metal-building-homes/",
      image: data.metalBuildingHomesImage,
    },
  ]

  const renderProductList = () => {
    if (
      pageData.category.name === "Commercial Buildings" ||
      pageData.category.name === "Storage Buildings" ||
      pageData.category.name === "Metal Warehouses"
    ) {
      return <ProductCardList data={currentData} location={location} />
    }

    return (
      <TabProductCardList
        data={currentData}
        category={pageData.category.name}
        location={location}
      />
    )
  }

  return (
    <Layout location={location}>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <HeroSection>
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home </Link> /{" "}
            <span>
              {pageData.category.name === "Commercial Buildings"
                ? "Commercial Metal Buildings"
                : pageData.category.name}
            </span>
          </BreadCrumb>
          <HeroCover>
            <LeftHero>
              <HeroPageTitle>{pageData.heroTitle}</HeroPageTitle>
              <HeroDesc
                dangerouslySetInnerHTML={{
                  __html: pageData.heroDescription.childMarkdownRemark.html,
                }}
              />
            </LeftHero>
            <RightHero>
              <HeroFormWrap>
                <HeroFormTitle>Get In Touch</HeroFormTitle>
                <HeroFormBody>
                  <GetTouchForm
                    location={location}
                    formName={`${pageData.category.name} Contact Form`}
                  />
                </HeroFormBody>
              </HeroFormWrap>
            </RightHero>
          </HeroCover>
        </Container>
      </HeroSection>

      {currentData.length !== 0 && (
        <SectionPage
          ept="80px"
          epb="80px"
          xpt="60px"
          xpb="60px"
          pt="40px"
          pb="40px"
          bg="#F4FBFF"
        >
          <Container>
            <SectionHeading textAlign="center">
              {pageData.productListTitle}
            </SectionHeading>
            {renderProductList()}
          </Container>
        </SectionPage>
      )}

      {pageData.formSection &&
        pageData.formSection.features.length > 0 &&
        pageData.formSection.features[0].roof === "Vertical Roof" && (
          <ProductCustomBuildingForm
            data={pageData.formSection}
            buildingType={pageData.category.name}
            location={location}
            category={pageData.category.name}
          />
        )}

      <SectionPage
        ept="80px"
        epb="80px"
        xpt="60px"
        xpb="60px"
        pt="40px"
        pb="40px"
        bg="#fff"
      >
        <Container>
          <MainPanel>
            <LeftPanel>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(pageData.content.content),
                }}
              />
            </LeftPanel>
            <RightPanel>
              <SidebarSticky className="isSticky">
                <DesignPlan />
                <AccreditedBusiness />
              </SidebarSticky>
            </RightPanel>
          </MainPanel>
        </Container>
      </SectionPage>

      <CallToAction location={location} />
    </Layout>
  )
}

export default ProductCategoryTemplate

export const pageQuery = graphql`
  query categoryLandigQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      productListTitle
      formSection {
        title
        features {
          title
          roof
          image
          description
        }
      }
      category {
        name
      }
      content {
        content
      }
    }
    allContentfulProduct(sort: { fields: shopOrder, order: ASC }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
        }
      }
    }
    utilityImage: file(relativePath: { eq: "durable-utility-buildings.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    storageImage: file(relativePath: { eq: "durable-storage-buildings.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    commercialImage: file(
      relativePath: { eq: "durable-commercial-buildings.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    metalShedsImage: file(relativePath: { eq: "durable-metal-sheds.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    metalWarehousesImage: file(
      relativePath: { eq: "durable-metal-warehouses.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    metalBuildingHomesImage: file(
      relativePath: { eq: "durable-metal-building-homes.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
